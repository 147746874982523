import { styled } from '@linaria/react';
import { AllocationOfCostsSummary } from '../../api/financials-client/financials-client.type';
import { UNCHECKED_COLOR } from './allocation-of-costs';
import { AllocationOfCostsItem } from './allocation-of-costs-item';
import { ShowAllocationOfCostsChartLine } from './allocation-of-costs.type';

type AllocationOfCostsItemListProps = {
  totalSummary: AllocationOfCostsSummary[];
  averageSummary: AllocationOfCostsSummary[];
  value: string;
  showAllocationOfCostsChartLines: ShowAllocationOfCostsChartLine[];
  updateShowChartLines: (checked: boolean, field: string, color: string) => void;
};

export const AllocationOfCostsItemList = ({
  totalSummary,
  averageSummary,
  value,
  showAllocationOfCostsChartLines,
  updateShowChartLines,
}: AllocationOfCostsItemListProps) => {
  const isCurrency = value.includes('cost');
  const fields = averageSummary.map((summary) => summary.field);

  return (
    <ScrollingList>
      {fields
        .sort((a, b) =>
          a === 'undefined' && b === 'undefined'
            ? 0
            : a === 'undefined'
            ? -1
            : b === 'undefined'
            ? 1
            : a.localeCompare(b)
        )
        .map((field, index) => {
          const current = totalSummary.find((obj) => obj.field === field);
          let currentVal;
          if (current === undefined) {
            currentVal = 0;
          } else {
            currentVal = +current[value as keyof AllocationOfCostsSummary] || 0;
          }

          const fieldData = showAllocationOfCostsChartLines.find((line) => line.field === field);

          // This is to avoid the average getting out of sync with the total in (Total vs Average)
          const filteredAverageSummaryValue = averageSummary.find((obj) => obj.field === field);
          let averageSummaryValue;
          switch (value) {
            case 'cost_per_task':
              averageSummaryValue = filteredAverageSummaryValue?.cost_per_task;
              break;
            case 'cost_per_point':
              averageSummaryValue = filteredAverageSummaryValue?.cost_per_point;
              break;
            case 'tasks':
              averageSummaryValue = filteredAverageSummaryValue?.tasks;
              break;
            case 'points':
              averageSummaryValue = filteredAverageSummaryValue?.points;
              break;
            default:
              averageSummaryValue = 0;
              break;
          }

          return (
            <AllocationOfCostsItem
              key={index}
              title={field === 'undefined' ? 'None' : field}
              color={fieldData?.color || UNCHECKED_COLOR}
              current={currentVal}
              average={averageSummaryValue as number}
              checked={fieldData?.show || false}
              currency={isCurrency}
              disabled={false}
              updateShowChartLines={updateShowChartLines}
            />
          );
        })}
    </ScrollingList>
  );
};

const ScrollingList = styled.div`
  height: 355px;
  width: 420px;
  overflow: auto;
`;
