import { Icon } from '@iconify/react';
import { styled } from '@linaria/react';
import { Collapse, Divider, Loader, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useQuery } from '@tanstack/react-query';
import { Fragment, useRef, useState } from 'react';
import { getCostVsOutputChartData } from '../../api/financials-client/financials-client';
import {
  CostVsBudgetChartData,
  CostVsBudgetChartResponse,
  CostVsBudgetSummary,
} from '../../api/financials-client/financials-client.type';
import { canvasHtmlDownload } from '../../helpers/image-downloader/image-downloader';
import { newCOLORS } from '../../styles/colors';
import {
  SmallerHeading,
  UppercaseCallout,
} from '../../styles/new-shared-styled-components/new-shared-styled-components';
import { DownloadPNGButton } from '../download-png-button.tsx';
import { getScopeNameSuffix } from '../edit-financial-data/edit-financial-data.helper';
import { FinancialsScope } from '../financials-scope/financials-scope.type';
import { CostsVsOutputChart } from './costs-vs-output-chart';
import { CostsVsOutputItem } from './costs-vs-output-item';
import { CostsVsOutputChartItemLabels, ShowCostsVsOutputChartLines } from './costs-vs-output.types';

export const CostsVsOutput = ({ startDate, endDate, portfolio, team, setQueryFinished }: FinancialsScope) => {
  const [opened, { toggle }] = useDisclosure(true);
  const downloadRef = useRef<HTMLDivElement>(null);
  const exceptionRef = useRef<HTMLDivElement>(null);
  const [showCostsVsOutputChartLines, setShowCostsVsOutputChartLines] = useState<ShowCostsVsOutputChartLines>({
    showCost: false,
    showCostTrend: false,
    showDailyBurn: true,
    showDailyBurnTrend: true,
    showThroughPut: true,
    showThroughPutTrend: false,
    showTasksCommited: true,
    showTasksCommitedTrend: false,
    showCostPerTask: true,
    showCostPerTaskTrend: false,
    showVelocity: false,
    showVelocityTrend: false,
    showPointsCommitted: false,
    showPointsCommittedTrend: false,
    showCostPerPoint: false,
    showCostPerPointTrend: false,
  });

  const [lastSummary, setLastSummary] = useState<CostVsBudgetSummary | null>(null);
  const [averageSummary, setAverageSummary] = useState<CostVsBudgetSummary | null>(null);
  const [chartData, setChartData] = useState<CostVsBudgetChartData[] | null>(null);

  const getCostVsOutputChartDataQuery = useQuery(
    ['getCostVsOutputChartData', startDate, endDate, portfolio, team],
    () =>
      portfolio &&
      startDate &&
      endDate &&
      team &&
      getCostVsOutputChartData(portfolio.id, startDate, endDate, team && team.id != 'aggregate' ? team.id : undefined),
    {
      enabled: !!(portfolio && startDate && endDate && team),
      onSuccess: (data: CostVsBudgetChartResponse) => {
        setLastSummary(data.last);
        setAverageSummary(data.average);
        setChartData(data.chart_data);
        if (setQueryFinished) {
          setQueryFinished(true);
        }
      },
      onError: () => {
        if (setQueryFinished) {
          setQueryFinished(true);
        }
      },
    }
  );

  const updateShowCostsVsOutputChartLines = (checked: boolean, label: CostsVsOutputChartItemLabels) => {
    setShowCostsVsOutputChartLines((prevShowCostsVsOutputChartLines) => ({
      ...prevShowCostsVsOutputChartLines,
      [`show${label}`]: checked,
    }));
  };

  return (
    <Fragment>
      <CostsVsOutputContainer ref={downloadRef}>
        <CollapseHeader>
          <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <CollapseIcon>
              {opened ? (
                <Icon icon="icon-park-solid:down-one" width={16} height={16} color={newCOLORS.black} onClick={toggle} />
              ) : (
                <Icon
                  icon="icon-park-solid:right-one"
                  width={16}
                  height={16}
                  color={newCOLORS.black}
                  onClick={toggle}
                />
              )}
            </CollapseIcon>
            <SmallerHeading>Costs vs output{getScopeNameSuffix(team, portfolio)}</SmallerHeading>
          </div>
          <div ref={exceptionRef}>
            {opened && (
              <DownloadPNGButton
                handleDownload={() => canvasHtmlDownload('Costs vs output', downloadRef, exceptionRef)}
                exceptionRef={exceptionRef}
              />
            )}
          </div>
        </CollapseHeader>
        <Collapse in={opened}>
          {getCostVsOutputChartDataQuery.isFetchedAfterMount ? (
            <CollapseContent>
              <CostsVsOutputItemBox>
                <CostsVsOutputItemHeaderWrapper>
                  <CostsVsOutputItemHeaderLeft>
                    <UppercaseCallout style={{ marginLeft: '9px' }}>Line</UppercaseCallout>
                    <UppercaseCallout style={{ marginLeft: '9px' }}>Trend</UppercaseCallout>
                  </CostsVsOutputItemHeaderLeft>
                  <CostsVsOutputItemHeaderRight>
                    <UppercaseCallout style={{ width: 125, textAlign: 'right' }}>
                      Last{' '}
                      <Tooltip
                        multiline
                        w={300}
                        position="right"
                        label={'Result for the most recently completed month, independent of range selected above'}
                        style={{ textAlign: 'left', textTransform: 'none' }}
                      >
                        <Icon icon="mdi:information-outline" width={18} height={18} color={newCOLORS.blue} />
                      </Tooltip>
                    </UppercaseCallout>
                    <UppercaseCallout style={{ width: 125, textAlign: 'right' }}>
                      Average{' '}
                      <Tooltip
                        multiline
                        w={300}
                        position="right"
                        label={'Total for selected item(s) divided by number of months from the range selected'}
                        style={{ textAlign: 'left', textTransform: 'none' }}
                      >
                        <Icon icon="mdi:information-outline" width={18} height={18} color={newCOLORS.blue} />
                      </Tooltip>
                    </UppercaseCallout>
                  </CostsVsOutputItemHeaderRight>
                </CostsVsOutputItemHeaderWrapper>

                <CostsVsOutputItem
                  label={CostsVsOutputChartItemLabels.cost}
                  color={newCOLORS.green}
                  title={'Cost'}
                  last={lastSummary?.cost || 0}
                  average={averageSummary?.cost || 0}
                  showCostsVsOutputChartLines={showCostsVsOutputChartLines}
                  updateShowCostsVsOutputChartLines={updateShowCostsVsOutputChartLines}
                  currency={true}
                  disabled={false}
                />
                <CostsVsOutputItem
                  label={CostsVsOutputChartItemLabels.dailyBurn}
                  color={newCOLORS.coral}
                  title={'Daily spend'}
                  last={lastSummary?.daily_burn || 0}
                  average={averageSummary?.daily_burn || 0}
                  showCostsVsOutputChartLines={showCostsVsOutputChartLines}
                  updateShowCostsVsOutputChartLines={updateShowCostsVsOutputChartLines}
                  currency={true}
                  disabled={false}
                />

                <Divider size="xs" style={{ margin: '8px 0px' }} />

                <CostsVsOutputItem
                  label={CostsVsOutputChartItemLabels.throughPut}
                  color={newCOLORS.blue}
                  title={'Throughput'}
                  description={'tasks completed'}
                  last={lastSummary?.tasks_completed || 0}
                  average={averageSummary?.tasks_completed || 0}
                  showCostsVsOutputChartLines={showCostsVsOutputChartLines}
                  updateShowCostsVsOutputChartLines={updateShowCostsVsOutputChartLines}
                  currency={false}
                  disabled={false}
                />
                <CostsVsOutputItem
                  label={CostsVsOutputChartItemLabels.tasksCommited}
                  color={newCOLORS.aqua}
                  title={'Tasks committed'}
                  last={lastSummary?.tasks_committed || 0}
                  average={averageSummary?.tasks_committed || 0}
                  showCostsVsOutputChartLines={showCostsVsOutputChartLines}
                  updateShowCostsVsOutputChartLines={updateShowCostsVsOutputChartLines}
                  currency={false}
                  disabled={false}
                />
                <CostsVsOutputItem
                  label={CostsVsOutputChartItemLabels.costPerTask}
                  color={newCOLORS.lighterGreen}
                  title={'Cost per task'}
                  last={lastSummary?.cost_per_task || 0}
                  average={averageSummary?.cost_per_task || 0}
                  showCostsVsOutputChartLines={showCostsVsOutputChartLines}
                  updateShowCostsVsOutputChartLines={updateShowCostsVsOutputChartLines}
                  currency={true}
                  disabled={false}
                />

                <Divider size="xs" style={{ margin: '8px 0px' }} />

                <CostsVsOutputItem
                  label={CostsVsOutputChartItemLabels.velocity}
                  color={newCOLORS.pink}
                  title={'Velocity'}
                  description={'points completed'}
                  last={lastSummary?.points_completed || 0}
                  average={averageSummary?.points_completed || 0}
                  showCostsVsOutputChartLines={showCostsVsOutputChartLines}
                  updateShowCostsVsOutputChartLines={updateShowCostsVsOutputChartLines}
                  currency={false}
                  disabled={false}
                />
                <CostsVsOutputItem
                  label={CostsVsOutputChartItemLabels.pointsCommitted}
                  color={newCOLORS.yellow}
                  title={'Points committed'}
                  last={lastSummary?.points_committed || 0}
                  average={averageSummary?.points_committed || 0}
                  showCostsVsOutputChartLines={showCostsVsOutputChartLines}
                  updateShowCostsVsOutputChartLines={updateShowCostsVsOutputChartLines}
                  currency={false}
                  disabled={false}
                />
                <CostsVsOutputItem
                  label={CostsVsOutputChartItemLabels.costPerPoint}
                  color={newCOLORS.orangeRed}
                  title={'Cost per point'}
                  last={lastSummary?.cost_per_point || 0}
                  average={averageSummary?.cost_per_point || 0}
                  showCostsVsOutputChartLines={showCostsVsOutputChartLines}
                  updateShowCostsVsOutputChartLines={updateShowCostsVsOutputChartLines}
                  currency={true}
                  disabled={false}
                />

                <Divider size="xs" style={{ margin: '8px 0px' }} />

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: 400,
                    margin: '8px 0px',
                  }}
                ></div>
              </CostsVsOutputItemBox>

              <div style={{ width: '99%', padding: '5px' }}>
                <CostsVsOutputChart showCostsVsOutputChartLines={showCostsVsOutputChartLines} chartData={chartData} />
              </div>
            </CollapseContent>
          ) : (
            <LoaderContainer>
              <Loader color={newCOLORS.indigo} size="lg" />
            </LoaderContainer>
          )}
        </Collapse>
      </CostsVsOutputContainer>
    </Fragment>
  );
};

const CostsVsOutputContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  margin-bottom: 16px;
  background-color: ${newCOLORS.white};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`;

const CollapseHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CollapseIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 6px;
  font-weight: 800;
  cursor: pointer;
`;

const CollapseContent = styled.div`
  padding: 12px;
  display: flex;
  gap: 1em;
`;

const CostsVsOutputItemBox = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
`;

const CostsVsOutputItemHeaderWrapper = styled.div`
  width: 400px;
  display: flex;
  flex-direction: row;
`;

const CostsVsOutputItemHeaderLeft = styled.div`
  display: flex;
  justify-content: start;
  flex-grow: 1;
`;

const CostsVsOutputItemHeaderRight = styled.div`
  display: flex;
  justify-content: end;
  flex-grow: 1;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 545px;
`;
