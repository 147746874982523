import { Chart as ChartJS } from 'chart.js';
import { NavigateFunction } from 'react-router-dom';
import { Measure, MeasureDataResponse, MeasureUnits } from '../../../api/work-periods-client/work-periods-client.type';
import { getOrCreateTooltip } from '../../../components/costs-vs-output/v2/costs-vs-output-tooltip-v2';
import { MeasureUnitMap } from '../../../store/process-analysis-store/process-analysis-store.type';
import { newCOLORS } from '../../../styles/colors';
let hideTooltipTimeout: NodeJS.Timeout | undefined;

export const measureComparisonTooltipHandler = (
  context: { chart: ChartJS; tooltip: any },
  measureData: MeasureDataResponse,
  measureUnitMap: MeasureUnitMap,
  navigate: NavigateFunction
) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  clearTimeout(hideTooltipTimeout);
  const activePointExists = !!chart.getActiveElements().length;

  if (tooltip.opacity === 0 || !activePointExists) {
    hideTooltipTimeout = setTimeout(() => {
      if (tooltipEl) {
        tooltipEl.style.opacity = '0';
        tooltipEl.style.pointerEvents = 'none';
      }
    }, 3000);
    return;
  }

  if (!tooltip.dataPoints || !tooltip.dataPoints[0]) {
    return;
  }
  const currentDataIndex = tooltip.dataPoints[0]?.dataIndex;
  if (currentDataIndex === undefined || measureData === null) {
    return;
  }
  const currentMeasure = tooltip.dataPoints[0].dataset.label as Measure;
  if (!currentMeasure) {
    return;
  }
  const currentValue = tooltip.dataPoints[0].dataset.data[currentDataIndex];
  const unit = currentMeasure in measureUnitMap ? measureUnitMap[currentMeasure] : '';
  const currentMeasureData = measureData[currentMeasure];
  const currentDate = Object.keys(currentMeasureData)[currentDataIndex];

  const isTrend = tooltip.dataPoints[0].dataset.borderDash && tooltip.dataPoints[0].dataset.borderDash.length > 0;
  const linkUrl = `/application/process-analysis/tasks?measure=${currentMeasure}&date=${currentDate}`;

  if (tooltip.body) {
    const tableHead = document.createElement('thead');

    const titleRow = document.createElement('tr');
    titleRow.style.borderWidth = '0';

    const titleTH = document.createElement('td');
    titleTH.style.borderWidth = '0';
    titleTH.style.fontSize = '12px';
    titleTH.style.fontWeight = 'bold';

    const titleString = (currentMeasure as string)
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    const text = isTrend ? document.createTextNode(`${titleString} (Trend)`) : document.createTextNode(titleString);
    titleTH.appendChild(text);
    titleRow.appendChild(titleTH);
    tableHead.appendChild(titleRow);

    const tableBody = document.createElement('tbody');

    const bodyRow = document.createElement('tr');
    bodyRow.style.borderWidth = '0';

    const bodyData = document.createElement('td');
    bodyData.style.borderWidth = '0';
    bodyData.style.fontSize = '12px';

    // add the value
    bodyRow.appendChild(bodyData);
    tableBody.appendChild(bodyRow);

    const linkAnchor = document.createElement('div');
    linkAnchor.style.display = 'inline';
    if (currentMeasure !== Measure.MissingDays && !isTrend) {
      linkAnchor.onclick = () => navigate(linkUrl);
      linkAnchor.setAttribute('role', 'button');
      linkAnchor.setAttribute('tabindex', '0');
      linkAnchor.setAttribute('aria-label', `View details for ${currentValue}`);
      linkAnchor.style.color = newCOLORS.lightPurple;
      linkAnchor.style.cursor = 'pointer';
      linkAnchor.style.textDecoration = 'underline';
      linkAnchor.style.textDecorationStyle = 'dashed';
    }
    linkAnchor.appendChild(document.createTextNode(currentValue.toString()));
    bodyData.appendChild(linkAnchor);

    const unitString = unit === MeasureUnits.Percentage ? '%' : unit.toLocaleLowerCase();
    bodyData.appendChild(document.createTextNode(` ${unitString}`));

    if (tooltipEl) {
      const tableRoot = tooltipEl.querySelector('table');

      if (tableRoot) {
        while (tableRoot.firstChild) {
          tableRoot.firstChild.remove();
        }

        tableRoot.appendChild(tableHead);
        tableRoot.appendChild(tableBody);
      }
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
    const tooltipOffsetPadding = 10;
    if (tooltipEl) {
      tooltipEl.style.opacity = '1';
      tooltipEl.style.pointerEvents = 'auto';
      tooltipEl.style.left = tooltip.caretX + positionX - tooltipEl.offsetWidth / 2 - tooltipOffsetPadding + 'px';
      tooltipEl.style.top = tooltip.caretY + positionY - tooltipEl.offsetHeight + tooltipOffsetPadding + 'px';
      tooltipEl.style.font = tooltip.options.bodyFont.string;
      tooltipEl.style.padding = '3px';
      tooltipEl.style.zIndex = '400';
    }
  }
};
