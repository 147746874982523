import { removeDuplicates } from '../tasks-table/tasks-table.helpers';
import { TaskRecord, TaskType } from '../tasks-table/tasks-table.type';
import { ProjectsTasksScope } from './projects-tasks.type';

/**
 * Generates a title based on the projects tasks scope.
 *
 * @param {ProjectsTasksScope} param - Object containing view, month, year, startDate, and endDate
 * @returns {string} The generated title string
 */
const makeTitle = ({ view, month, year, startDate, endDate }: ProjectsTasksScope): string => {
  const titleComponents = [];

  if (view) {
    titleComponents.push(view);
  }

  titleComponents.push('Tasks');

  if (month && year) {
    const monthStr = new Date(Number(year), Number(month) - 1).toLocaleString('default', {
      month: 'long',
    });
    titleComponents.push(`${monthStr} ${year}`);
  } else if (startDate && endDate) {
    titleComponents.push(`${startDate} - ${endDate}`);
  }

  return titleComponents.join(' - ');
};

/**
 * Formats an array of process tasks into an array of task records for the table.
 *
 * @param {TaskRecord[]} tasks - The array of process tasks to be formatted
 * @return {TaskRecord[]} The formatted array of task records
 */
const formatTasks = (tasks: TaskRecord[]): TaskRecord[] => {
  return tasks.map(
    (task) =>
      ({
        id: task.id,
        name: task.name,
        title: task.title,
        url: task.url,
        created_date: task.created_date,
        points: task.points,
        type: task.type as TaskType,
        epic: task.epic,
        initiatives: removeDuplicates(task.initiatives),
      } as TaskRecord)
  );
};

export { formatTasks, makeTitle };
