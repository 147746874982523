import { useContext } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { CrumbItem } from '../../components/breadcrumb/breadcrumb.type';
import { ProjectContext, SidebarContext } from '../../contexts';
import { formatAllocationOfCostTitle, formatDate } from '../../helpers';
import { onPortfolioBreadcrumbClick } from '../side-bar/side-bar.helper';
import { makeTitle } from './projects-tasks.helpers';
import { ProjectsTasksScope } from './projects-tasks.type';

/**
 * Generates and returns a ProjectsTasksScope object based on the search parameters.
 *
 * @return {ProjectsTasksScope} The ProjectsTasksScope object containing projectId, category, filter, view, startDate, endDate, month, and year.
 */
const useProjectsTasksScope = (): ProjectsTasksScope => {
  const [searchParams] = useSearchParams();

  const projectId = searchParams.get('projectId');
  const category = searchParams.get('category');

  let filter = searchParams.get('filter');
  let view = searchParams.get('view');
  if (!filter) {
    if (view === 'Cost Per Task' || view === 'Points Committed' || view === 'Tasks Committed') {
      filter = 'committed';
    } else if (category != null) {
      filter = view;
      view = formatAllocationOfCostTitle(view || '');
    } else {
      filter = 'done';
    }
  }

  let startDate = searchParams.get('startDate');
  let endDate = searchParams.get('endDate');
  const month = searchParams.get('month');
  const year = searchParams.get('year');
  if (month && year && !startDate && !endDate) {
    startDate = formatDate(new Date(Number(year), Number(month) - 1, 1));
    endDate = formatDate(new Date(Number(year), Number(month), 0));
  }

  return {
    projectId,
    category,
    filter,
    view,
    startDate,
    endDate,
    month,
    year,
  };
};

/**
 * Generates breadcrumbs based on the provided scope and project information.
 *
 * @param {ProjectsTasksScope} scope - the scope for which the breadcrumbs are being generated
 * @returns {CrumbItem[]} an array of breadcrumb items representing the navigation path
 */
const useBreadcrumbs = (scope: ProjectsTasksScope): CrumbItem[] => {
  const { projectId } = scope;
  const { portfolioId } = useParams<{ portfolioId: string }>();
  const { project, setProject } = useContext(ProjectContext);
  const { navItems, setNavItems } = useContext(SidebarContext);

  const breadcrumbs: CrumbItem[] = [
    {
      labelName: 'Portfolio',
      href: '/application/dashboard',
      onNavigate: () => onPortfolioBreadcrumbClick(setProject, navItems, setNavItems),
    },
  ];

  if (projectId) {
    breadcrumbs.push({
      labelName: (project?.name as string) || 'Project',
      href: `/application/project/${projectId}`,
    });
  }

  breadcrumbs.push({ labelName: 'Financials', href: `/application/financials/portfolio/${portfolioId}` });
  breadcrumbs.push({
    labelName: makeTitle(scope),
  });

  return breadcrumbs;
};

export { useBreadcrumbs, useProjectsTasksScope };
