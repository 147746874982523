import { QueryObserverResult, UseQueryOptions, useQuery } from '@tanstack/react-query';
import { MinifiedTask, ProjectsTasksConfig } from './task-client.type';
import { fetchProjectsTasks } from './tasks-client';

/**
 * Query manager for fetching projects tasks.
 *
 * @param {ProjectsTasksConfig} config - Configuration object for fetching projects tasks
 * @param {UseQueryOptions<MinifiedTask[]>} options - Options for the useQuery hook
 * @return {{ data: MinifiedTask[] | undefined; query: QueryObserverResult<MinifiedTask[]> }} Object containing data and query result
 */
const useProjectsTasks = (
  { portfolioId, projectId, category, filter, startDate, endDate }: ProjectsTasksConfig,
  options?: UseQueryOptions<MinifiedTask[]>
): { data: MinifiedTask[] | undefined; query: QueryObserverResult<MinifiedTask[]> } => {
  const query = useQuery({
    queryKey: ['projects-tasks', portfolioId, projectId, category, filter, startDate, endDate],
    queryFn: () =>
      portfolioId && startDate && endDate && filter
        ? fetchProjectsTasks({
            portfolioId,
            startDate,
            endDate,
            filter,
            projectId: projectId || undefined,
            category: category || undefined,
          })
        : Promise.reject('Cannot get projects tasks'),
    ...options,
  });

  return { data: query.data, query };
};

export { useProjectsTasks };
