import {
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  TooltipModel,
} from 'chart.js';
import { Fragment, useContext, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { CostVsBudgetChartData } from '../../api/financials-client/financials-client.type';
import { PortfolioContext } from '../../contexts';
import { newCOLORS } from '../../styles/colors';

import { simpleLinearRegression } from '../../helpers/math-helpers/math-helpers';
import { externalTooltipHandler } from './costs-vs-output-tooltip';
import { CostsVsOutputChartProps, Position, SanitizedData, ShowCostsVsOutputChartLines } from './costs-vs-output.types';

const POINT_RADIUS = 4;
const POINT_HOVER_RADIUS = 6;
const BORDER_WIDTH = 2;

const createDataset = (label: string, data: number[], order: number, color: string, yAxisID: 'y' | 'y1') => ({
  label,
  data,
  order,
  borderColor: color,
  backgroundColor: color,
  pointStyle: 'circle',
  pointRadius: POINT_RADIUS,
  pointHoverRadius: POINT_HOVER_RADIUS,
  borderWidth: BORDER_WIDTH,
  yAxisID,
});

const createTrendDataset = (label: string, trendData: number[], order: number, color: string, yAxisID: 'y' | 'y1') => {
  return {
    label: `${label} Trend`,
    data: trendData,
    fill: false,
    borderColor: color,
    borderDash: [10, 5],
    pointRadius: 0,
    order,
    backgroundColor: color,
    pointHoverRadius: 0,
    borderWidth: BORDER_WIDTH,
    yAxisID,
  };
};

/**
 * Format the data according to the chart.js requirements
 * @param data
 * @returns formatted data
 */
const formatData = (
  data: SanitizedData,
  showCostsVsOutputChartLines: ShowCostsVsOutputChartLines
): ChartData<'line'> => {
  const dataset: ChartData<'line'> = {
    labels: data.labels,
    datasets: [],
  };

  const addTrendDataset = (
    condition: boolean,
    label: string,
    data: number[],
    order: number,
    color: string,
    yAxisID: 'y' | 'y1'
  ) => {
    if (!condition) {
      return;
    }
    const xAxis = Array.from({ length: data.length }, (_value, index) => index);
    const line = simpleLinearRegression(xAxis, data);
    if (line) {
      const { slope, intercept } = line;
      const trendData = xAxis.map((x) => slope * x + intercept);
      dataset.datasets.push(createTrendDataset(label, trendData, order, color, yAxisID));
    }
  };

  const addDataset = (
    condition: boolean,
    label: string,
    data: number[],
    order: number,
    color: string,
    yAxisID: 'y' | 'y1'
  ) => {
    condition && dataset.datasets.push(createDataset(label, data, order, color, yAxisID));
  };

  const {
    showCost,
    showCostTrend,
    showDailyBurn,
    showDailyBurnTrend,
    showThroughPut,
    showThroughPutTrend,
    showTasksCommited,
    showTasksCommitedTrend,
    showCostPerTask,
    showCostPerTaskTrend,
    showVelocity,
    showVelocityTrend,
    showPointsCommitted,
    showPointsCommittedTrend,
    showCostPerPoint,
    showCostPerPointTrend,
  } = showCostsVsOutputChartLines;

  addDataset(showCost, 'Cost', data.cost, 9, newCOLORS.green, 'y');
  addTrendDataset(showCostTrend, 'Cost Trend', data.cost, 1, newCOLORS.green, 'y');
  addDataset(showDailyBurn, 'Daily Burn', data.dailyBurn, 10, newCOLORS.coral, 'y');
  addTrendDataset(showDailyBurnTrend, 'Daily Burn Trend', data.dailyBurn, 2, newCOLORS.coral, 'y');
  addDataset(showThroughPut, 'Throughput', data.throughput, 13, newCOLORS.blue, 'y1');
  addTrendDataset(showThroughPutTrend, 'Throughput Trend', data.throughput, 5, newCOLORS.blue, 'y1');
  addDataset(showTasksCommited, 'Tasks Committed', data.tasksComitted, 14, newCOLORS.aqua, 'y1');
  addTrendDataset(showTasksCommitedTrend, 'Tasks Committed Trend', data.tasksComitted, 6, newCOLORS.aqua, 'y1');
  addDataset(showCostPerTask, 'Cost Per Task', data.costPerTask, 11, newCOLORS.lighterGreen, 'y');
  addTrendDataset(showCostPerTaskTrend, 'Cost Per Task Trend', data.costPerTask, 3, newCOLORS.lighterGreen, 'y');
  addDataset(showVelocity, 'Velocity', data.velocity, 15, newCOLORS.pink, 'y1');
  addTrendDataset(showVelocityTrend, 'Velocity Trend', data.velocity, 7, newCOLORS.pink, 'y1');
  addDataset(showPointsCommitted, 'Points Committed', data.pointsCommitted, 16, newCOLORS.yellow, 'y1');
  addTrendDataset(showPointsCommittedTrend, 'Points Committed Trend', data.pointsCommitted, 8, newCOLORS.yellow, 'y1');
  addDataset(showCostPerPoint, 'Cost Per Point', data.costPerPoint, 12, newCOLORS.orangeRed, 'y');
  addTrendDataset(showCostPerPointTrend, 'Cost Per Point Trend', data.costPerPoint, 4, newCOLORS.orangeRed, 'y');

  return dataset;
};

export const CostsVsOutputChart = ({ showCostsVsOutputChartLines, chartData }: CostsVsOutputChartProps) => {
  useEffect(() => {
    ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);
  }, []);

  const { portfolio } = useContext(PortfolioContext);

  const mapToSanitizedData = (data: CostVsBudgetChartData[]): SanitizedData => {
    data.sort((a, b) => a.year - b.year || a.month - b.month);

    const labels = data.map((item) => {
      const monthStr = new Date(0, item.month - 1).toLocaleString('default', { month: 'short' });
      return `${monthStr} ${item.year.toString().slice(-2)}`;
    });

    const cost = data.map((item) => item.projects.reduce((acc, project) => acc + project.cost, 0));
    const dailyBurn = data.map((item) => item.projects.reduce((acc, project) => acc + project.daily_burn, 0));
    const throughput = data.map((item) =>
      item.projects.reduce((acc, project) => acc + project.completed_tasks.length, 0)
    );
    const velocity = data.map((item) =>
      item.projects.reduce(
        (acc, project) => acc + project.completed_tasks.reduce((pointSum, task) => pointSum + (task.points || 0), 0),
        0
      )
    );
    const pointsCommitted = data.map((item) =>
      item.projects.reduce(
        (acc, project) => acc + project.committed_tasks.reduce((pointSum, task) => pointSum + (task.points || 0), 0),
        0
      )
    );
    const tasksComitted = data.map((item) =>
      item.projects.reduce((acc, project) => acc + project.committed_tasks.length, 0)
    );

    const costPerTask = cost.map((c, index) => c / (throughput[index] || 1));
    const costPerPoint = cost.map((c, index) => c / (velocity[index] || 1));

    return {
      labels,
      cost,
      dailyBurn,
      throughput,
      tasksComitted,
      costPerTask,
      velocity,
      pointsCommitted,
      costPerPoint,
    };
  };

  const {
    showCost,
    showCostTrend,
    showDailyBurn,
    showDailyBurnTrend,
    showThroughPut,
    showThroughPutTrend,
    showTasksCommited,
    showTasksCommitedTrend,
    showCostPerTask,
    showCostPerTaskTrend,
    showVelocity,
    showVelocityTrend,
    showPointsCommitted,
    showPointsCommittedTrend,
    showCostPerPoint,
    showCostPerPointTrend,
  } = showCostsVsOutputChartLines;

  // Determine if the y-axis is in use
  const yAxisInUse =
    showCost ||
    showCostTrend ||
    showDailyBurn ||
    showDailyBurnTrend ||
    showCostPerTask ||
    showCostPerTaskTrend ||
    showCostPerPoint ||
    showCostPerPointTrend;

  // Determine if the y1-axis is in use
  const y1AxisInUse =
    showThroughPut ||
    showThroughPutTrend ||
    showTasksCommited ||
    showTasksCommitedTrend ||
    showVelocity ||
    showVelocityTrend ||
    showPointsCommitted ||
    showPointsCommittedTrend;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    pointStyle: false,
    cubicInterpolationMode: 'monotone',
    borderWidth: 5,
    aspectRatio: 2.5,
    layout: {
      padding: {
        top: 20,
        right: 0,
      },
    },

    scales: {
      x: {
        title: {
          display: true,
          text: 'Months',
          font: {
            size: 16,
          },
        },
        ticks: {
          font: {
            size: 14,
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        position: 'left' as Position,
        title: {
          display: true,
          text: 'Cost',
          color: yAxisInUse ? undefined : newCOLORS.gray,
          font: {
            size: 16,
          },
        },
        ticks: {
          font: {
            size: 14,
          },
          color: yAxisInUse ? undefined : newCOLORS.gray,
          callback: (value: number | string) => {
            return Number(value) >= 1000 ? `$${Number(value) / 1000}k` : `$${value}`;
          },
        },
      },
      y1: {
        beginAtZero: true,
        position: 'right' as Position,
        title: {
          display: true,
          text: 'Units',
          color: y1AxisInUse ? undefined : newCOLORS.gray,
          font: {
            size: 16,
          },
        },
        ticks: {
          color: y1AxisInUse ? undefined : newCOLORS.gray,
          font: {
            size: 14,
          },
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },

    plugins: {
      legend: {
        display: false,
      },
      annotation: {
        common: {
          drawTime: 'afterDraw',
        },
      },

      tooltip: {
        enabled: false,
        external: (context: { chart: ChartJS; tooltip: TooltipModel<'line'> }) =>
          externalTooltipHandler(context, chartData, portfolio?.id),
      },
      filler: {
        propagate: true,
        drawTime: 'beforeDatasetsDraw' as const,
      },
    },
  };

  return (
    <Fragment>
      {chartData ? (
        <Line data={formatData(mapToSanitizedData(chartData), showCostsVsOutputChartLines)} options={options} />
      ) : (
        <Fragment />
      )}
    </Fragment>
  );
};
